.optimizador-section-container{
    width: 100vw;
    padding: 0 80px;
    margin: 30px 0 45px 0;
}
.contenedor{
    width: calc(100% - 180px);
}
.optimizador-section-container h4{
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.7px;
    margin: 0 0 15px 0;
}
.optimizador-divisor{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.optimizador-divisor hr{
    width: 100%;
}
.optimizador-divisor p{
    text-align: center;
    background-color: #F7F7F7;
    padding: 0 10px;
    margin-top: -22px;
    width: 17ch;
}
.inputs-container .form-material-divs{
    flex: 0 0 45%;
    max-width: 45%;
    margin: 0 0 15px 0;
    border: 1px solid rgba(0,0,0,0.4);
    width: 100%;
    border-radius: 3px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.inputs-container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.inputs-container .input-withErrors{
    flex: 0 0 45%;
    max-width: 45%;
    margin: 0 0 15px 0;
    border: none;
}
.input-withErrors p {
    margin: 7px 3px;
    padding: 0;
    color: #E73B33;
    display: block;
}
.inputs-container .input-divisor{
    border: 1px solid rgba(0,0,0,0.4);
    width: 100%;
    border-radius: 3px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.inputs-container div input{
    background-color: transparent;
    width: calc(100% - 20px);
    border: none;
    outline: none;
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
}
.inputs-container div input::placeholder{
    font-size: 16px;
    font-family: 'Source Sans Pro', sans-serif;
}
.inputs-container div svg{
    font-size: 20px;
    margin-right: 5px;
}
.cantos-container{
    padding: 0 100px;
    display: flex;
    justify-content: space-around;
    margin: 0 0 25px 0;
}
.buttons-optimizador{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.button-add{
    background-color: #696968;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    border: none;
    padding: 6px 0;
    border-radius: 3px;
    margin-bottom: 15px;
    letter-spacing: 0.8px;
    cursor: pointer;
}
.button-send{
    background-color: #E73B33;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    border: none;
    padding: 6px 0;
    border-radius: 3px;
    letter-spacing: 0.8px;
    cursor: pointer;
}
.top-25{
    margin-top: 25px;
}
.mm{
    margin: 0;
}
.tutorial{
    padding: 4px 15px;
    border-radius: 3px;
    border: 2px solid #E73B33;
    color: #E73B33;
}
.img-opt-info{
    width: 400px;
    max-width: 400px;
}
.img-opt-info img{
    width: 100%;
}
.container-loader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container-loader .loader{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid rgba(0,0,0,0.6);
    border-left: 0px;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(360deg);
    }
}

.searcher-order-container{
    width: 100%;
    margin-top: -10px;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 10px;
}
.searcher-order-container input{
    padding: 7px 0px 7px 14px;
    width: calc(100% - 92px);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 0.5px solid rgba(0,0,0,0.4);
    outline: none;
    font-size: 16px;
}

.searcher-order-container button{
    padding: 7px 10px;
    cursor: pointer;
    margin-left: -1px;
}

.searcher-order-container button{
    background-color: #E73B33;
    color: #F7F7F7;
    font-size: 17px;
    font-weight: 500;
    border: none;
}

@media (min-width: 900px){
    .optimizador-section-container .contenedor .precios-buttons a{
        margin-top: -45px;
    }
}
@media (max-width: 880px){
    .img-opt-info{
        order: 3;
    }
    .drive{
        order: 2;
    }
}
@media (max-width: 600px){
    .optimizador-section-container{
        padding: 0 30px;
    }
    .img-opt-info{
        width: 100%;
        max-width: 100%;
    }
    .searcher-order-container{
        margin-top: -25px;
    }
    .contenedor{
        width: calc(100% - 60px);
    }
    .cantos-container{
        padding: 0;
    }
}
@media (max-width: 500px){
    .cantos-container {
        flex-wrap: wrap;
    }
    .cantos-container div{
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        justify-content: center;
    }
    .top-15{
        margin-top: 15px;
    }
    .inputs-container .form-material-divs,
    .inputs-container .input-withErrors{
        flex: 0 0 100%;
        max-width: 100%;
    }
}