.marca-descrip{
    font-size: 16px;
    font-weight: 600;
}
.visit-store{
    display: block;
    width: calc(100% - 10px);
    font-size: 20px;
    font-weight: 600;
    color: #696968;
    text-align: center;
    margin: 15px 0 20px 0;
}