.card-placas-20{
    flex: 0 0 20%;
    max-width: 20%;
    margin-top: 15px;
}
.card-placas-20 img{
    width: calc(100% - 8px);
    border: 4px solid #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.card-placas-20 h5{
    width: 100%;
    padding: 8px 0;
    text-align: center;
    background-color: #696968;
    color: #fff;
    margin-top: -6px;
    margin-bottom: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 5px;
}
.card-placas-20 table{
    width: 100%;
    border-spacing: 0px;
}
.card-placas-20 table tr th{
    border-bottom: 2px solid #69696869;
}
.card-placas-20 table tr td{
 border-bottom: 2px solid #69696869;
 text-align: top;
}
.card-placas-20 tr td p{
    margin: 2px 0;
}
@media (max-width: 880px){
    .card-placas-20{
        flex: 0 0 50%;
        max-width: 50%;
        margin-top: 30px;
    }
}
@media (max-width:560px){
    .card-placas-20{
        flex: 0 0 50%;
        max-width: 50%;
        margin-top: 30px;
    }
}