.Precios-container{
    width: 100vw;
    margin: 30px 0 45px 0;
    padding: 0 80px;
}
.precios-contenedor-delimited{
    width: calc(100% - 160px);
}
.precios-buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.precios-buttons a p{
    margin: 0;
    text-align: center;
}
.precios-buttons a{
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.sheets{
    color: #23A566;
    border: 2px solid #23A566;
    border-radius: 3px;
    padding: 4px 25px;
}
.pdf{
    color: #000;
    border: 2px solid #000;
    border-radius: 3px;
    padding: 4px 25px;
}
.drive{
    color: #4A453E;
    border: 2px solid #4A453E;
    border-radius: 3px; 
    padding: 4px 15px;
}
.precios-buttons svg{
    font-size: 20px;
    margin-right: 10px;
}
.search{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    border-radius: 3px;
    margin-bottom: 5px;
}
.search svg{
    font-size: 24px;
    margin: 4px 5px 4px 8px;
    color: rgba(0, 0, 0, 0.6);
}
.search input::placeholder,
.search input{
    font-size: 18px;
    background-color: transparent;
    border: 0;
    outline: none;
}
.search input{
    width: calc(100% - 50px);
}
.price{
    justify-content: center;
}
.paginator-container{
    width: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    visibility: hidden;
    display: none;
}
.paginator{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.4);
}
.paginator button{
    border: 0;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.paginator button:first-child{
    border-right: 1px solid rgba(0, 0, 0, 0.4);
}
.paginator button:last-child{
    border-left: 1px solid rgba(0, 0, 0, 0.4);
}
.paginator p{
    padding: 5px 15px;
    margin: 0;
}
.Precios-container table tr th:nth-child(2),
.Precios-container table tr td:nth-child(2){
    text-align: left;
}
.Precios-container .tableProducts td:last-child{
    justify-content: center;
}
.comment{
    width: 100%;
}
.comment a{
    text-decoration: none;
}
.comment p{
    margin: 0;
    text-align: right;
    font-size: 14px;
    color: #E73B33;
}
.paginator-container.show{
    visibility: visible;
    display: flex;
}
@media (max-width: 880px){
    .precios-buttons a{
        width: 100%;
        padding: 4px 0;
    }
    .drive, .pdf{
        margin-top: 15px;
    }
    .precios-buttons{
        flex-direction: column;
    }
}
@media (max-width:650px){
    .Precios-container{
        padding: 0 30px;
    }
    .precios-contenedor-delimited{
        width: calc(100% - 60px);
    }
}