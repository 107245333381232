.newMaterial{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 8px;
    border-radius: 3px;
    background-color: #F0F0F0;
}
.newMaterial button{
    padding: 5px 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
}
.newMaterial button p{
    margin: 0;
}
.newMaterial button svg{
    font-size: 20px;
}
.newMaterial-details{
    display: none;
    visibility: hidden;
}
.newMaterial-details.show{
    display: block;
    visibility: visible;
}
.medidas{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
.medidas p{
    display: flex;
    align-items: center;
}
.medidas p svg{
    margin-right: 5px;
}
.medidas p span{
    margin-left: 5px;
}
.newMaterial ul{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    margin-top: -15px;
}
.newMaterial ul li{
    width: 80px;
    display: flex;
    align-items: center;
    margin-top: 15px;
}
.subtitle-newMaterial{
    background-color: #F0F0F0 !important;
}
.deleteButton{
    text-align: center;
    background-color: #d70419c5;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    color: #fff;
    display: flex;
    justify-content: center !important;
}