.body{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
}
.container-total{
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
}
@media (min-width: 1920px){
    .body{
        align-items: center;
    }
    .max-1920{
        border: 1px solid rgba(0, 0, 0, 0.2);
        overflow: hidden;
        max-width: 1920px;
    }
}