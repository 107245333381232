.MenuAdministracion{
    display: flex;
    justify-content: center;
    width: 100vw;
    padding: 15px 0;
}
.menuItems{
    border-left: 2px solid rgba(0, 0, 0, 0.4);
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    padding: 0 15px;
}
.menuItems a{
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: #696968;
}
.menuItems a:first-child{
    padding-right: 15px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
}
.menuItems a:last-child{
    padding-left: 15px;
}