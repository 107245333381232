.ofertasBubble{
  background-color: #E73B33;
  position: fixed;
  left: 0;
  bottom: 30px;
  padding: 10px 40px;
  box-shadow: 0 2px 6px 4px rgba(184, 59, 59, 0.34);
  color: white;
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}
.ofertasBubble span{
  position: absolute;
  border-top: 25px solid transparent;
  border-left: 25px solid #E73B33;
  position: absolute;
  right: -25px;
  border-top-right-radius: 3px;
}
.ofertasBubble span:first-child{
  top: 0;
  transform: rotate(90deg);
}
.ofertasBubble span:last-child{
  bottom: 0;
}
@media (max-width: 390px){
  .ofertasBubble{
    font-size: 20px;
  }
}
@media (max-width: 350px){
  .ofertasBubble{
    padding: 10px 20px;
  }
}