.cards-container{
    padding: 0 80px;
    margin: 50px 0;
    width: 100vw;
}
.cards{
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: calc(100vw - 160px);
    flex-wrap: wrap;
}
.cards a{
    flex: 0 0 calc(50% - 17px);
    max-width: calc(50% - 17px);
    text-decoration: none;
    color: #000;

}
.cards .card{
    flex: 0 0 100%;
    max-width: 100%;
}
@media (max-width: 610px){
    .cards-container{
        padding: 0 30px;
    }
    .cards{
        width: calc(100vw - 60px);
    }
    .cards a{
        flex: 100%;
        max-width: 100%;
    }
}