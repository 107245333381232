.card{
    flex: 0 0 calc(50% - 17px);
    max-width: calc(50% - 17px);
    overflow: hidden;
    background-color: #fff;
    border-radius: 3px;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
}
.card-img{
    height: 160px;
    overflow: hidden;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}
.card-img img{
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.card-title{
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-title h5{
    margin: 6px 0 0 0;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.card-title h5 span{
    font-weight: 300;
    font-size: 14px;
    margin: 14px 0 8px 8px ;
}
.card-description{
    padding: 0 10px;
    margin: 8px 0;
}
.card-buttons{
    background-color: #E73B33;
    display: flex;
    align-items: center;
}
.card-buttons a{
    width: calc(100% - 40px);
    cursor: pointer;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #fff;
    text-decoration: none;
}
.card-buttons button{
    width: 40px;
    border: none;
    outline: none;
    background-color: #D9D9D9;
    cursor: pointer;
    height: 28px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    border-top: 1px solid rgba(0, 0, 0, 0.4);
}
.modal-container{
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999999;
    top:0;
    left: 0;
    display: none;
    visibility: hidden;
}
.modal-container.show{
    display: flex;
    visibility: visible;
}
.modal{
    background-color: #FFF;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 6px 4px rgb(0 0 0 / 24%);
}
.modal p{
    padding: 0 20px;
    margin: 15px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
}
.buttons-modal{
    display: flex;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.buttons-modal button{
    font-size: 18px;
    letter-spacing: 0.8px;
    font-weight: 500;
    color: #fff;
}
.buttons-modal button:first-child{
    flex: 0 0 30%;
    max-width: 30%;
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    background-color: #696968;
}
.buttons-modal button:last-child{
    flex: 0 0 70%;
    max-width: 70%;
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    background-color: #d70419f1;
}
@media (max-width: 880px){
    .card{
        flex: 0 0 100%;
        max-width: 100%;
    }
}