.tableProducts td:last-child .buttons-modal button:first-child{
    margin: 0 0;
    padding: 5px 0;
    background-color: rgba(98, 189, 75, 0.597);
    color: rgb(35, 37, 35);
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    border-top: 1px solid rgba(0, 0, 0, 0.4);
    border-bottom-left-radius: 3px;
}
.tableProducts td:last-child .buttons-modal button:last-child{
    margin: 0 0;
    padding: 5px 0;
    color: #000;
    border-left: 1px solid rgba(0, 0, 0, 0.4);
    background-color: #e3332aeb;
    border-bottom-right-radius: 3px;
}
.comment-doc{
    position: absolute;
    z-index: 5;
    background-color: #000;
    color: #FFF;
    max-width: 150px;
    padding: 5px 7px;
    margin-top: 0px;
    border-radius: 3px;
    font-size: 13px;
    display: none;
    visibility: hidden;
}
.comment-doc.show{
    display: block;
    visibility: visible;
}