.wpp-bot-button{
    position: fixed;
    padding: 18px 18px 13px 18px;
    bottom: 30px;
    right: 80px;
    background-color: #4caf50;
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
    border:none;
    cursor: pointer;
    z-index: 100003;
    box-shadow: 0 2px 6px 4px rgb(59 184 78 / 24%);
}
.wpp-container{
    background-color: #4caf50;
    position: fixed;
    bottom: 90px;
    right: 30px;
    width: 350px;
    border-radius: 10px;
    overflow: hidden;
    z-index: 100002;
    box-shadow: 0 2px 6px 4px rgb(59 184 78 / 26%);
    visibility: hidden;
    display: none;
}
.wpp-container.show{
    visibility: visible;
    display: block;
}
.wpp-header{
    padding: 20px;
    color: #fff;
}
.wpp-header-divisor{
    display: flex;
    flex-direction: row;
}
.wpp-header-divisor p{
    font-size: 14px;
    margin: 0 0 0 10px;
}
.wpp-header-divisor svg{
    font-size: 30px;
    margin-top: -5px;
}
.wpp-assist-container{
    background-color: #fff;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 20px;
    width: 100%;
    border: none;
    cursor: pointer;
}
.wpp-assist-container h6{
    margin: 0 0 5px 0;
    font-size: 14px;
    text-align: start;
}
.wpp-assist-container p{
    margin: 0;
    text-align: start;
}
.wpp-assist-container div{
    margin-left: 10px;
}
.wpp-assist-container img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.wpp-container-input{
    background-color: #4caf50;
    position: fixed;
    bottom: 90px;
    right: 30px;
    width: 350px;
    border-radius: 10px;
    z-index: 100002;
    overflow: hidden;
    box-shadow: 0 2px 6px 4px rgb(59 184 78 / 29%);
    visibility: hidden;
    display: none;
}
.wpp-container-input.show{
    visibility: visible;
    display: block;
}
.wpp-input-header{
    background-color: #4caf50;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 20px;
    width: 100%;
    border: none;
}
.wpp-input-header h6{
    margin: 0 0 5px 0;
    font-size: 14px;
    text-align: start;
}
.wpp-input-header p{
    margin: 0;
}
.wpp-input-header div{
    margin-left: 10px;
}
.wpp-input-header img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.messages-wpp-container{
    background-color: rgb(230, 221, 212);
    padding: 30px;
}
.messages-wpp-container div{
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0px 12px 24px 0px;
    padding: 7px 14px 6px;
    width: 150px;
    border-radius: 8px;
    border-top-left-radius: 0px;
}
.user-name{
    margin: 0 0 5px 0;
    font-size: 12px;
    color: rgb(0 0 0 / 60%);
}
.wpp-saludo{
    margin: 0 0 5px 0;
    font-size: 15px;
    color: #000;
}
.wpp-pregunta{
    margin: 0 0 3px -3px;
    font-size: 15px;
    color: #000;
}
.message-input{
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}
.message-input textarea{
    border: none;
    resize: none;
    width: calc(100% - 97px);
}
.message-input a{
    outline: none;
    border: none;
    background-color: rgb(200, 200, 200);
    color: rgb(72, 72, 72);
    cursor: pointer;
    border-radius: 2px;
    font-weight: 600;
    padding: 5px 8px;
    text-decoration: none;
}
.message-input a:hover{
    text-decoration: underline;
}
@media (max-width: 410px){
    .wpp-container,
    .wpp-container-input{
        right: 15px;
    }
}
@media (max-width: 390px){
    .wpp-container,
    .wpp-container-input{
        width: calc(100% - 20px);
    }
}
@media (max-width:600px){
    .wpp-bot-button{
        right: 30px;
    }
}