.products-container{
    width: 100%;
    padding: 30px 80px;
}
.div-submenu{
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #fff;
}
.div-submenu h5{
    margin: 8px 0;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    padding: 0 10px;
    cursor: pointer;
}
.buttons-submenu button{
    width: 100%;
    outline: none;
    text-align: start;
    padding: 10px 8px;
    border: 2px solid #fff;
    cursor: pointer;
    font-weight: 600;
    background-color: #F9F9F9;
    color: #696968;
    border-radius: 6px;
}
.buttons-submenu button:hover{
    background-color: #696968;
    color: #fff;
}
.products-container{
    display: flex;
    flex-direction: row;
}
.content-products-container p{
    line-height: 24px;
}
.content-products-container{
    width: calc(100vw - 460px);
    overflow: hidden;
    padding-left: 10px;
}
.default-title{
    font-size: 30px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 0 0 5px 0;
}
.default-title img{
    height: 60px;
    margin-left: 7px;
}
.divisor-hr{
    color: #696968;
}
.img-default{
    max-height: 300px;
    max-width: 90%;
    border-radius: 5px;
}
.cards-placas-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.card-placas-30{
    flex: 0 0 30%;
    max-width: 30%;
}
.card-placas-30 img,
.card-placas-60 img,
.card-placas-45 img{
    width: calc(100% - 8px);
    border: 4px solid #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.marco-img{
    border: 0.5px solid #69696824;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
    width: calc(100% - 1px);
}
.img-card{
    width: 100%;
}
.card-placas-30 h5,
.card-placas-60 h5,
.card-placas-45 h5{
    width: 100%;
    padding: 8px 0;
    text-align: center;
    background-color: #696968;
    color: #fff;
    margin-top: -6px;
    margin-bottom: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 5px;
}
.card-placas-30 table,
.card-placas-60 table,
.card-placas-45 table{
    width: 100%;
    border-spacing: 0px;
}
.card-placas-30 table tr th,
.card-placas-60 table tr th,
.card-placas-45 table tr th{
    border-bottom: 2px solid #69696869;
}
.card-placas-30 table tr td,
.card-placas-60 table tr td,
.card-placas-45 table tr td{
 border-bottom: 2px solid #69696869;
 text-align: top;
}
.card-placas-30 table tr td p,
.card-placas-60 table tr td p,
.card-placas-45 table tr td p{
    margin: 2px 0;
}
.title-placas{
    font-size: 24px;
    font-weight: 600;
    margin: 15px 0;
    color: #636363;
}
.space{
    justify-content: start;
}
.space div{
    margin-right: 20px;
}
.p-cards{
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 5px 0 0 0;
}
.p-cards h6{
    font-size: 14px;
    margin: 5px 7px 0 0;
}
.p-cards-comp{
    margin-top: 3px;
    margin-bottom: 3px;
}
.p-cards-comp span{
    font-size: 15px;
    font-weight: 600;
}
.still .p-cards-comp{
    margin: 2px;
    line-height: 18px;
}
.cent{
    justify-content: center;
}
.card-placas-60{
    flex: 0 0 60%;
    max-width: 60%;
    margin-top: 30px;
}
.card-placas-45{
    flex: 0 0 45%;
    max-width: 45%;
    margin-top: 30px;
}
@media (max-width: 880px){
    .products-container{
        padding: 30px 30px;
    }
    .content-products-container{
        width: calc(100vw - 370px);
    }
    .card-placas-30{
        flex: 0 0 45%;
        max-width: 45%;
        margin-bottom: 10px;
    }
    .card-placas-45{
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 30px;
    }
}
@media (max-width:740px){
    .products-container{
        padding: 30px 80px;
        flex-direction: column;
    }
    .content-products-container,
    .div-submenu{
        width: calc(100vw - 160px);
    }
    .content-products-container{
        padding-left: 0;
        margin-top: 30px;
    }
}
@media (max-width:560px){
    .products-container{
        padding: 30px 30px;
        flex-direction: column;
    }
    .content-products-container,
    .div-submenu{
        width: calc(100vw - 60px);
    }
    .card-placas-30{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .card-placas-60{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .card-placas-45{
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 30px;
    }
}