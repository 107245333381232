.banner-general-container{
    width: 100vw;
    height: 150px;
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
}
.banner-general-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 150px;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0 80px;
}
.banner-general-content h3{
    font-size: 26px;
    color: #fff;
    font-weight: 500;
    margin: 0 0 15px 0;
    text-align: left;
    letter-spacing: 1px;
}
.banner-general-content span{
    width: 13ch;
    height: 4px;
    background-color: #E73B33;
}
@media (max-width: 610px){
    .banner-general-content{
        padding: 0 30px;
        height: 120px;
    }
    .banner-general-container{
        height: 120px;
    }
}