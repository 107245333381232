.card-service-container{
    flex: 0 0 33%;
    max-width: 33%;
    display: flex;
    justify-content: center;
    height: 100%;
    margin-bottom: 30px;
}
.card-service{
    width: calc(100% - 40px);
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    height: 100%;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
}
.card-service .card-content{
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 10px 10px 10px;
    text-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
}
.card-service .card-content h5{
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 20px;
    letter-spacing: 0.8px;
    font-weight: 600;
}
.card-service .card-content p{
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-top: 0;
    height: 80px;
    display: flex;
    align-items: center;
}
@media (max-width:920px){
    .card-service-container{
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (max-width:740px){
    .card-service-container{
        flex: 0 0 100%;
        max-width: 100%;
    }
}