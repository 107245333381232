.slider-container{
    max-width: 100%;
    width: 100vw;
    overflow: hidden;
}
.slider-show-container{
    display:flex;
    flex-wrap:nowrap;
    width: 100vw;
    max-width:100vw;
}
.slider{
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.slider-content{
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    width: 240px;
    margin-left: 80px;
    padding: 5px 15px;
}
.slider-img{
    width: 100vw;
}
.slider-content h3{
    margin: 5px 0;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 1.5px;
}
.slider-content hr{
    width: 14ch;
    height: 0.3px;
    background-color: #fff;
    border: none;
    margin-left: 0;
}
.slider-content h6{
    margin: 5px 0;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.8px;
}
.arrows-container{
    position: absolute;
    width: 100%;
    z-index: 2;
    height: calc(100vh - 150px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.arrows-container span{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 15px 7px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 500px){
    .slider-content{
        margin-left: 20px;
    }
    .slider, .arrows-container{
        height: calc(100vh - 200px);
    }
}