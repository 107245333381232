.PreServicios-container{
    max-width: 100%;
    flex-direction: column;
    padding: 30px 80px;
}
.div-50-preservicios{
    flex: 0 0 calc(50% - 80px);
    max-width: calc(50% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
