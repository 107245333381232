.banner-empresa{
    width: 100vw;
    height: calc(100vw/2.26);
    max-height: calc(100vh - 180px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow-y: hidden;
}
.empresa-title{
    color: #fff;
    padding-left: 90px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.empresa-title h3{
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 12px;
}
.empresa-title span{
    width: 18ch;
    height: 4px;
    background-color: #E73B33;
}
.empresa-title h4{
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}
.contenido-empresa{
    max-width: 100vw;
    padding: 10px 80px 15px 80px;
    line-height: 25px;
}
.contenido-empresa p{
    font-size: 18px;
}
.goals-empresa{
    max-width: 100%;
    flex-direction: row;
    display: flex;

}
.goals-empresa div{
    flex: 0 0 33%;
    display: flex;
    flex-direction: column;
}
.goals-empresa div p{
    flex: 0 0 85%;
    padding: 0 5px;
    margin-top: 10px;
}
.arrow{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 30px;
    color: #fff;
    position: absolute;
    z-index: 3;
    animation: rebote 2s infinite;
}
@keyframes rebote{
    0%{
        margin-top: -60px;
    }
    50%{
        margin-top: -40px;
    }
    100%{
        margin-top: -60px;
    }
}
.goals-empresa div h5{
    margin: 20px 0 0 0;
    height: 100%;
    font-size: 18px;
    padding-left: 20px;
    color: #696968;
}
.goals-empresa div ul{
    margin-top: 10px;
}
.goals-empresa .valores h5{
    padding-left: 40px;
}
@media (max-width:910px){
    .goals-empresa .valores{
        order: 2;
    }
    .goals-empresa .mision{
        order: 3;
    }
}
@media (max-width:820px){
    .goals-empresa{
        flex-direction: column;
    }
    .empresa-title{
        padding-left: 30px;
    }
    .contenido-empresa{
        padding: 10px 30px 15px 30px;
    }
    .goals-empresa div{
        flex: 0 0 100%;
    }
    .goals-empresa div ul{
        margin-block-start: 0;
        padding-inline-start: 20px;
    }
    .goals-empresa .valores h5{
        padding-left: 20px;
    }
}
@media (max-width: 760px){
    .goals-empresa .valores{
        order: 3;
    }
    .goals-empresa .mision{
        order: 2;
    }
    .arrow{
        display: none;
        visibility: hidden;
    }
}