.first-images-pinturas{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.first-images-pinturas img{
    flex: 0 0 20%;
    max-width: 20%;
    border-radius: 5px;
}
.sd-images-pinturas{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.sd-images-pinturas img{
    flex: 0 0 13%;
    max-width: 13%;
    border-radius: 5px;
}
@media (max-width: 880px){
    .first-images-pinturas img{
        flex: 0 0 30%;
        max-width: 30%;
        margin-top: 5px;
    }
    .sd-images-pinturas img{
        flex: 0 0 30%;
        max-width: 30%;
        margin-top: 5px;
    }
}
@media (max-width: 600px){
    .first-images-pinturas img{
        flex: 0 0 50%;
        max-width: 50%;
        margin-top: 5px;
    }
    .sd-images-pinturas img{
        flex: 0 0 30%;
        max-width: 30%;
        margin-top: 5px;
    }
}