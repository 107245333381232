.experience-container,
.service-container,
.optimizador-container,
.tienda-container{
    max-width: 100%;
    padding: 15px 80px;
}
.experience-container{
    display: flex;
    flex-direction: column;
}
.experience-container .flex-wrap-row,
.optimizador-image-container,
.tienda-image-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.div-50{
    flex: 0 0 50%;
    max-width: 50%;
}
.home-tittles h3{
    font-size: 28px;
    margin: 0px 0 10px 0;
    font-weight: 600;
    text-align: center;
    color: #696968;
}
.home-tittles hr{
    width: 15ch;
    height: 4px;
    background-color: #E73B33;
    margin-bottom: 25px;
}
.experience-text{
    min-height: 187px;
}
.experience-text h4{
    margin: 0 0 10px 0;
    font-size: 22px;
    color: #696968;
}
.experience-text p{
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    margin-top: 0;
}
.video-yt{
    display: flex;
    justify-content: center;
    align-items: center;
}
.tienda-container{
    background-color: #fff;
}
.optimizador-image-container img,
.tienda-image-container img{
    width: 350px;
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.1);
}
.optimizador-image-container .div-center{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.tienda-image-container .div-center{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.border-video iframe{
    width: 350px; 
    height: 178px;
}
.tienda-container a,
.optimizador-container a{
    text-decoration: none;
}
.tienda-container .experience-text p,
.optimizador-container .experience-text p{
    margin-bottom: 30px;
}
.go-to-opt{
    padding: 6px 15px;
    background-color: #E73B33;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 3px;
    box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.1);
}
.go-to-shop{
    padding: 6px 15px;
    background-color: #696968;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 3px;
    box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.1);
}
.Items-service-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
@media (min-width: 810px){
    .home-tittles{
        margin-top: 40px;
    }
    .experience-container .flex-wrap-row,
    .optimizador-image-container,
    .tienda-image-container{
        margin-bottom: 40px;
    }
}
@media (max-width: 930px){
    .experience-container{
        max-width: 100%;
        padding: 15px 30px;
    }
    .service-container,
    .optimizador-container,
    .tienda-container{
        max-width: 100%;
        padding: 40px 30px;
    }
}
@media (max-width: 810px){
    .div-50{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .experience-container .experience-text h4{
        display: none;
        visibility: hidden;
    }
    .experience-container .experience-text{
        margin-top: 0;
    }
    .experience-text{
        text-align: center;
        margin-top: 30px;
    }
    .tienda-image-container .div-center,
    .optimizador-image-container .div-center{
        justify-content: center;
    }
    .tienda-image-container .experience-text{
        order: 2;
    }
    .tienda-image-container .image-tienda{
        order: 1;
    }
}
@media (max-width:500px){
    .experience-container .flex-wrap-row,
    .optimizador-image-container,
    .tienda-image-container{
        margin: 15px 0;
    }
    .experience-text p{
        font-size: 18px;
    }
}
@media (max-width:430px){
    .optimizador-image-container img,
    .tienda-image-container img{
        width: 100%;
    }
    .border-video iframe{
        width: calc(100vw - 60px);
        height: calc((100vw * 0.51) - 60px);
        margin: 30px 0 15px 0;
    }
}