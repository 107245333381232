.footer-container{
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    flex-wrap: wrap;
    padding: 10px 30px 0 30px;
    width: 100vw;
    max-width: 100%;
}
.contactus h5{
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
    text-decoration: underline;
    color: #696968;
    margin-top: 0;
}
.contactus{
    order: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.copy-container{
    order: 2;
}
.contactus ul{
    text-align: left;
    list-style: none;
    margin-top: 5px;
    margin-left: -10px;
}
.Footer-phone{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    text-decoration: none;
}
.contactus ul li:nth-child(2){
    margin-top: 5px;
}
.contactus ul li:nth-child(4) span,
.contactus ul li:nth-child(5) span{
    font-size: 12px;
    font-weight: 600;
}
.contactus ul li{
    display: flex;
    align-items: center;
}
.contactus ul li svg{
    margin-right: 5px;
}
.Footer-mail{
    font-size: 13px;
    margin-right: 10px;
    text-decoration: none;
    margin: 4px 4px;
}
.address{
    margin-left: 4px;
}
.copy-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
}
.copyrigth{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.copyrigth img{
    width: 170px;
    margin: 0 10px;
}
.copy-container h5{
    color: #696968;
    text-decoration: underline;
    font-size: 15px;
    margin: 10px 0 15px;
}
.Footer-redes svg{
    height: 24px;
    color: #696968;
}
.Footer-redes svg:last-child{
    margin-left: 15px;
    height: 22px
}
.footer-ig svg{
    font-size: 30px;
}
@media (max-width: 543px){
    .copy-container{
        order: 1;
    }
    .contactus{
        order: 2;
        margin-top: -10px;
    }
    .footer-container{
        padding: 0;
    }
}