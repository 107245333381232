.slider-marcas-container{
    background-color: #EDEBEB;
    padding-top: 40px;
    padding-bottom: 20px;
}
.knauf-section{
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}
.knauf-section-img{
    display: flex;
    flex-direction: row;
}
.knauf-section h4{
    font-size: 20px;
    font-weight: 500;
    margin-right: 15px;
    text-align: right;
}
.knauf-section img{
    height: 70px;
}
.knauf-section img:first-child{
    margin-right: 10px;
}
.slider-marcas{
    flex: 0 0 100vw;
    max-width: 100vw;
    overflow: hidden;
    padding: 0 30px;
}
.slider-hidden{
    overflow: hidden;
}
.sliderMarcasShow{
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
}
.sliderImg-marcas{
    flex: 0 0 25%;
    max-width: 25%;
}
.sliderImg-marcas img{
    width: 100%;
    max-width: calc(100% - 10px);
    margin: 5px;
}
@media (max-width: 745px){
    .sliderImg-marcas{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .knauf-section img{
        height: 55px;
    }
    .knauf-section-img{
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 620px){
    .knauf-section{
        margin: 10px 0 30px 0;
    }
    .knauf-section img{
        height: 55px;
    }
    .knauf-section h4{
        font-size: 20px;
        margin-right: 25px;
    }
    .slider-marcas{
        padding: 0 15px;
    }
}