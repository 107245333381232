.novedades-container{
    padding: 0 80px;
    margin: 30px 0;
}
.novedades-container hr{
    border-top: 0.5px solid rgba(0,0,0,0.3);
}
.novedades-container h2{
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.8px;
    margin: 5px 0;
}
.novedades-container p{
    font-size: 16px;
}
.novedad-img-container{
    height: 220px;
    flex: 0 0 65%;
    max-width: 65%;
    overflow: hidden;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.4);
}
.novedad-img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
@media (max-width: 650px){
    .novedades-container{
        padding: 0 30px;
    }
    .novedad-img-container{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .novedades-container h2{
        font-size: 22px;
    }
    .novedades-container p{
        font-size: 18px;
    }
}