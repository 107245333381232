.cards-news-administracion{
    display: flex;
    flex-wrap: wrap;
    padding: 0 80px;
    margin-bottom: 45px;
    gap: 30px;
}
.createNew{
    width: 100vw;
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}
.createNew a{
    background-color: #696968;
    color: #fff;
    text-decoration: none;
    padding: 8px 70px;
    border-radius: 3px;
    box-shadow: 0 2px 1px 0px rgb(0 0 0 / 10%);;
}
.loading-container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.loading-container div{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border-left: 2px solid rgb(0 0 0 / 30%);
    border-top: 2px solid rgb(0 0 0 / 30%);
    border-right: 2px solid rgb(0 0 0 / 30%);
}
@media (max-width: 750px){
    .cards-news-administracion{
        padding: 0 30px;
    }
}