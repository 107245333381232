.E404{
    background: white;
    max-width: 360px;
    margin: 20px auto 16px;
    padding: 32px 24px 16px;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.E404 h2{
    color: #E73B33;
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 8px;
}
.E404 h3{
    font-size: 22px;
    font-weight: 600;
    color: rgba(0,0,0,0.6);
    margin: 0 0 16px;
}
.E404 p{
    line-height: 140%;
    margin: 16px 0 24px;
    font-size: 14px;
}
.E404 img{
    max-height: 340px;
    transform: rotate(-90deg);
    margin-left: 60px;
    margin-top: -60px;
}
.E404 a{
    display: block;
    text-align: center;
    background: #E73B33;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    padding: 16px;
    border-radius: 4px;
    margin-top: -30px;
}