.images-ferreteria{
    display: flex;
    flex-wrap: wrap;
}
.images-ferreteria img{
    flex: 0 0 50%;
    max-width: 50%;
}
@media (max-width: 650px){
    .images-ferreteria img{
        flex: 0 0 100%;
        max-width: 100%;
    }
}