.docs-container{
    width: 100vw;
    margin: 30px 0 45px 0;
    padding: 0 80px;
}
.contenedor-max{
    width: calc(100% - 160px);
}
.contenedor-max h4{
    margin-top: 0;
}
#prod-title{
    margin-top: 30px;
}
.tableProducts {
    border-radius: 3px;
    box-shadow: 1px 1px 2px #a4a4a4;
    overflow-x: scroll;
    width: 100%;
    min-width: 550px;
    border-spacing: 0px;
  }
.tableContainer {
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    overflow-x: auto;
    margin-top: 10px;
}
.tableProducts th {
    background-color: white;
    color: black;
    font-size: 15px;
    font-weight: 600;
    border: none;
    padding: 8px 3px;
}
.tableProducts th:first-child,
.tableProducts td:first-child {
    text-align: left;
    padding-left: 10px;
}
.tableProducts td:last-child{
    display: flex;
    text-align: right;
    justify-content: flex-end;
    margin: 0;
}
.tableProducts td:last-child button{
    padding: 0 5px;
    background-color: transparent;
    border:none;
    margin: 0 5px;
    cursor: pointer;
}
.tableProducts td:last-child svg{
    font-size: 20px;
}
.tableProducts td {
    border: none;
    padding: 5px 5px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
.tableProducts tr:last-child td {
    border: none;
}
.tableProducts tbody tr:nth-child(odd) {
    background-color: rgb(206, 199, 194);
}
@media (max-width: 760px){
    .docs-container{
        padding: 0 30px;
    }
    .contenedor-max{
        width: calc(100% - 60px);
    }
}