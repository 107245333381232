.productos-menu{
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    background-color: transparent;
    border:none;
    outline: none;
    display: flex;
    align-items: center;
    font-size: 15px;
    padding:0;
    cursor: pointer;
}
.productos-menu svg{
    margin-left: 5px;
}
.menu-floating{
    position: absolute;
    z-index: 100001;
    display: flex;
    flex-direction: column !important;
    background-color: #F7F7F7;
    align-items: flex-start !important;
    text-transform: uppercase;
    font-size: 14px;
    padding: 3px 15px 10px 15px;
    border-bottom: 2px solid #E73B33;
    box-shadow: 1px 2px 1px 1px rgb(0 0 0 / 10%);
    display: none;
    visibility: hidden;
}
.menu-floating.show{
    display: block;
    visibility: visible;
}
.menu-floating li{
    margin-top: 8px;
}
@media (max-width: 880px){
    .menu-floating{
        position: relative;
    }
    .productos-menu{
        margin: auto;
        padding-left: 15px;
    }
    .menu-floating{
        font-size: 13px;
        padding-left: 30px;
    }
    .menu-container-mobile .menu-floating li{
        margin-top: 8px;
    }
    .menu-container-mobile .menu-floating li:nth-child(6){
        margin-bottom: 0px;
    }
    .menu-container-mobile .menu-floating{
        padding-left: 15px;
    }
    .menu-floating{
        box-shadow: none;
    }
}
@media (max-width: 750px){
    .menu-container-mobile .menu-floating li{
        font-size: 15px;
    }
}