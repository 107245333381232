.formulary{
    display: flex;
    flex-direction: column;
    padding: 0 80px;
    margin: 30px 0 45px 0;
}
.input-title{
    width: 31ch;
    border: 0.8px solid rgba(0, 0, 0, 0.4);
    padding: 5px;
    margin: 5px 0;
    border-radius: 3px;
    background-color: transparent;
    outline: none;
}
.input-description{
    min-height: 57px;
    min-width: 80%;
}
textarea{
    border: 0.8px solid rgba(0, 0, 0, 0.4);
    padding: 5px;
    margin: 5px 0;
    border-radius: 3px;
    resize: vertical;
    background-color: transparent;
    outline: none;
    font-family: 'Source Sans Pro', sans-serif;
}
.formulary .input-title::placeholder, 
.formulary .input-title{
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.8px;
}
.formulary .input-description::placeholder, 
.formulary .input-description{
    font-size: 15px;
    font-weight: 400;
}
.input-text, .input-sdText{
    min-height: 180px;
    font-size: 16px;
    letter-spacing: 0.8px;
}
.input-sdImg, .input-img{
    display: none;
}
.formulary label div{
    height: 220px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
}
.formulary label{
    flex: 0 0 65%;
    max-width: 65%;
    height: 220px;
    overflow: hidden;
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.4);
    background-color: #fff;
    cursor: pointer;
}
.novedad-img-container{
    max-height: 220px !important;
}
.formulary-button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formulary-button button{
    margin-top: 15px;
    padding: 7px 25px;
    border: none;
    background-color: #E73B33;
    font-size: 19px;
    font-weight: 500;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
}
@media (max-width: 600px){
    .formulary{
        padding: 0 30px;
    }
    .formulary label{
        flex: 0 0 100%;
        max-width: 100%;
        height: 150px;
    }
    .input-title{
        flex: 0 0 calc(100% - 10px);
        max-width: calc(100% - 10px);
    }
}