
.Header-menu-container{
    position: sticky;
    top:0;
    max-width: 100vw;
    z-index: 100000;
    padding: 15px 30px;
    box-shadow: 2px 6px 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #FFF;
}
.Header-info{
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: #F7F7F7;
    padding: 0 30px;
    align-items: center;
}
.menu-container ul a,
.menu-container-mobile ul a{
    color: #000;
    text-decoration: none;
    font-weight: 600;
}
.opt-anchor{
    color: #FFF !important;
}
.first-phone, .second-phone, .divisor, .Header-mail, .Header-anchor{
    margin: 7px 5px 7px 0;
}
.Header-icon{
    font-weight: 700;
    font-size: 12px;
}
.Header-Place{
    font-weight: 700;
    font-size: 12px;
    margin: 0 4px;
}
.Header-phone{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.628);
    font-weight: 600;
    text-decoration: none;
}
.Header-mail{
    font-size: 13px;
    margin-right: 10px;
    font-weight: 600;
    text-decoration: none;
}
.fb-icon{
    margin-right: 10px;
}
.Header-icon-ig, .Header-icon-fb{
    color: rgba(0, 0, 0, 0.600);
    font-size: 20px;
    margin-right: 10px;
}
.Header-tienda{
    font-weight: 500;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.600);
    padding: 5px 10px;
    border-radius: 2px;
    color: #FFF;
    text-decoration: none;
    letter-spacing: 1px;
}
.Header-menu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 5;
}
.menu-container ul{
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
}
.menu-container ul li{
    margin-right: 25px;
    font-weight: 600;
}
.Optimizador-link{
    font-weight: 500;
    font-size: 14px;
    background-color: #E73B33;
    padding: 10px 10px;
    border-radius: 2px;
    margin-top: -9px;
    color: #FFF;
    font-weight: 500;
    text-decoration: none;
    letter-spacing: 1px;
}
.logo-container{
    display: flex;
    align-items: center;
}
.logo-container a img{
    width: 250px;
}
.Header-menu{
    background-color: #FFF;
}
.menu-container-mobile ul{
    display: none;
    visibility: hidden;
    width: 100vw;
    list-style: none;
    padding-inline-start: 0px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: -15px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.button-menu button{
    font-size: 30px;
    height: 35px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0;
}
.menu-container-mobile ul.show{
    display: block;
    visibility: visible;
}
.menu-container-mobile ul li{
    padding: 0 15px;
    text-align: center;
    margin-top: 15px;
    margin-left: -15px;
    width: 100%;
    font-weight: 600;
}
.menu-container-mobile ul li:last-child,
.menu-container-mobile ul li:nth-child(6){
    border-bottom: 0;
    text-align: center;
}
.menu-container-mobile ul li:nth-child(6){
    margin-bottom: 20px;
}
.Header-tienda-mobile{
    font-weight: 500;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.600);
    padding: 5px 10px;
    border-radius: 2px;
    color: #FFF;
    text-decoration: none;
    letter-spacing: 1px;
    width: 7ch;
    margin: auto;
    display: none;
    visibility: hidden;
}
.menu-container-mobile .Header-tienda-mobile{
    color: #FFF;
}
.active{
    font-weight: 700;
    color: #E73B33 !important;
    pointer-events: none;
}
.Optimizador-link .active{
    color: #FFF !important;
    cursor: pointer;
}
@media (max-width:880px) {
    .margin{
        margin-bottom: 15px;
    }
    .menu-container{
        display: none;
        visibility: hidden;
    } 
    .Header-menu{
        flex-wrap: wrap;
    }
    .menu-container-mobile,
    .button-menu{
        visibility: visible;
        display: block;
    }
}
@media (max-width:750px) {
    .Header-menu-container{
        padding: 10px 15px;
    }
    .Header-tienda-mobile{
        display: block;
        visibility: visible;
    }
    .Header-info{
        display: none;
        visibility: hidden;
    } 
    .menu-container-mobile ul li{
        font-size: 18px;
    }
}
@media (min-width:880px){
    .menu-container-mobile, .button-menu{
        display: none;
        visibility: hidden;
    }
}
@media(min-width: 1920px){
    .Header-menu-container{
        width: 100%;
    }
    .Header-info{
        width: 100%;
    }
}