.login-container{
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
.login-content{
    width: 300px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px 4px rgb(0 0 0 / 10%);
    padding: 20px;
    display: flex;
    justify-items: center;
    flex-direction: column;
    margin-top: -50px;
}
.login-content h5{
    margin: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.8px;
    color: #696968;
}
.login-content form input{
    margin-top: 15px;
    width: calc(100% - 14px);
    padding: 7px;
    border: 0.5px solid rgb(0 0 0 / 26%);
    border-radius: 3px;
    font-size: 16px;
    outline: none;
}
.login-content button{
    margin-top: 8px;
    width: 100%;
    background-color: #E73B33;
    border: none;
    outline: none;
    padding: 5px 0px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.8px;
    border-radius: 4px;
    color: #fff;
}
.error{
    font-size: 13px;
    margin: 3px 0 0 0;
    color: #E73B33;
    visibility: hidden;
    display: none;
}
.error.show{
    visibility: visible;
    display: block;
}